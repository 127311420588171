<template>
  <div class="map_box">
    <img class="map" src="@/assets/images/map.jpg" />
    <div class="map_tag">
      <div
        :class="`tag tag_${index + 1}`"
        v-for="(item, index) in list"
        :key="index"
      >
        <el-popover
          placement="bottom"
          :title="item.title"
          width="200"
          trigger="hover"
          :content="item.mail"
        >
          <el-button slot="reference">click</el-button>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Map',

  data() {
    return {
      list: [
        {
          add: '中国大陆',
          title: 'Faye / Martin',
          mail: '',
        },
        {
          add: '中国台湾',
          title: 'Gary / Ivy Huang',
          mail: 'gary.chi@isobar.com   / Writetoivy@gmail.com',
        },
        {
          add: '中国香港',
          title: '陈一枬 Viveca',
          mail: 'viveca@we-mkt.com',
        },
        {
          add: '德国',
          title: 'Stephan Horvath',
          mail: 'email@stephanhorvath.com',
        },
        {
          add: '泰国',
          title: '陈高登 Gordon',
          mail: 'gordontan1@mac.com',
        },
        {
          add: '印度',
          title: 'Ujaya Shakya',
          mail: 'shakyau@gmail.com / ujaya@outreachnepal.com',
        },
        {
          add: '尼泊尔',
          title: 'Ujaya Shakya',
          mail: 'shakyau@gmail.com / ujaya@outreachnepal.com',
        },
        {
          add: '巴基斯坦',
          title: 'Ujaya Shakya',
          mail: 'shakyau@gmail.com / ujaya@outreachnepal.com',
        },
        {
          add: '斯里兰卡',
          title: 'Ujaya Shakya',
          mail: 'shakyau@gmail.com / ujaya@outreachnepal.com',
        },
        {
          add: '孟加拉国',
          title: 'Ujaya Shakya',
          mail: 'shakyau@gmail.com / ujaya@outreachnepal.com',
        },
        {
          add: '美国',
          title: 'Marilee / Jay Pants /Kyle Stirling',
          mail: 'kylestir@indiana.edu',
        },
        {
          add: '澳大利亚',
          title: 'Kevin Lee / Nick Qi',
          mail: '',
        },
        {
          add: '新西兰',
          title: 'Glyn MacLean',
          mail: 'glyn@redcirclenetwork.com',
        },
        {
          add: '东南亚、亚太',
          title: '汤伟杰 Andrew Tong',
          mail: 'andrew@aikendigital.com',
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.map_box {
  background-color: #e2f2fa;
  position: relative;
  height: 653px;
  overflow: hidden;
  .map {
    display: block;
    position: absolute;
    width: 1900px;
    left: 50%;
    transform: translateX(-50%);
  }
  .map_tag {
    position: absolute;
    z-index: 2;
    width: 1900px;
    left: 50%;
    transform: translateX(-50%);
    height: 653px;
  }
  .tag {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 100px;
    top: 200px;
    // background-color: #000;
    // cursor: pointer;
    .el-button {
      height: 100%;
      opacity: 0;
      width: 100%;
    }
  }

  .tag_1 {
    left: 1250px;
    top: 272px;
  }
  .tag_2 {
    left: 1298px;
    top: 321px;
  }
  .tag_3 {
    left: 1248px;
    top: 321px;
  }
  .tag_4 {
    left: 923px;
    top: 203px;
  }
  .tag_5 {
    left: 1240px;
    top: 362px;
  }
  .tag_6 {
    left: 1157px;
    top: 354px;
  }
  .tag_7 {
    left: 1179px;
    top: 312px;
  }
  .tag_8 {
    left: 1128px;
    top: 301px;
  }
  .tag_9 {
    left: 1172px;
    top: 386px;
  }
  .tag_10 {
    left: 1208px;
    top: 329px;
  }
  .tag_11 {
    left: 559px;
    top: 254px;
  }
  .tag_12 {
    left: 1370px;
    top: 494px;
  }
  .tag_13 {
    left: 1474px;
    top: 552px;
  }
  .tag_14 {
    left: 1260px;
    top: 408px;
  }
}
</style>
