<template>
  <div class="world_box main">
    <Map></Map>

    <div class="tips4 container" style="margin-top: 50px">
      <div class="p1" @click="toDetail('1')">
        <img src="@/assets/images/a9.png" />
        <div class="name">Brand Ambassador</div>
      </div>
      <div class="p2" @click="toDetail('2')">
        <img src="@/assets/images/a9.png" />

        <div class="name">Country Chapter</div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '@/components/Map.vue';
export default {
  name: 'World',
  components: { Map },
  data() {
    return {};
  },
  created() {},
  methods: {
    toDetail(cat) {
      this.$router.push({
        path: '/WorldDetail',
        query: { cat },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.world_box {
  .title_ {
    text-align: center;
    margin-bottom: 20px;
  }
  .text_ {
    text-align: center;
    padding-bottom: 50px;
  }

  .tips4 {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    .p1,
    .p2 {
      width: 377px;
      height: 212px;
      background-color: #e9edf2;
      line-height: 212px;
      font-size: 36px;
      text-align: center;
      margin-left: 20px;
      cursor: pointer;
      position: relative;
      .name {
        white-space: pre-wrap;
        position: absolute;
        font-size: 24px;
        font-weight: bold;
        width: 100%;
        top: 0;

        text-align: center;
        line-height: 212px;
      }
    }
    .p1:hover,
    .p2:hover {
      background-color: #2b8bc3;
    }
    .name:hover {
      color: #fff;
    }
  }
}
@media (max-width: 1000px) {
  .world_box {
    .tips4 {
      margin-top: 50px;
      display: block;
      .p1,
      .p2 {
        margin: 10px auto;
      }
    }
  }
}
</style>
